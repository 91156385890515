.Footer {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #657b83;
    background-color: #fdf6e3;
    padding: 0 10px;
}

.App.darkmode .Footer {
    color: #c5c8c6;
    background-color: #1d1f21;
}

.Footer .button {
    background: inherit;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 48px;
    margin-left: 25px;
    width: 48px;
}

.Footer .github-link img {
    width: 48px;
    margin-left: 25px;
}


@media only screen and (min-width: 720px) {
    .Footer {
        justify-content: center;
        padding: 0;
    }

    .Footer .button, .Footer .github-link img {
        width: 30px;
        font-size: 2.5em;
    }
}