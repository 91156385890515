html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}

pre {
    margin: 0;
}

html, body, #root {
    height: 100%;
    font-family: monospace;
}

.App {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1 0 auto;
    padding: 1em 1em 0;
    background-color: #fdf6e3;
}

.App.darkmode .content {
    background-color: #1d1f21;
}

@media only screen and (min-width: 720px) {
    .content {
        display: flex;
        align-items: center;
    }

    .profile {
        width: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: flex-start;
    }
}

.profile img {
    width:100%; 
    max-width: 500px;
    object-fit: cover;
}

footer {
    flex-shrink: 0;
}